.part-main {
  .banner-image {
    height: "100px";
  }
  background-color: #fff;
  h2 {
    font-weight: 400;
    margin-left: 10px;
    line-height: 1.7;
    font-size: 36px;
    font-family: Verdana, sans-serif;
    color: #232324;
  }
  .part-box {
    text-align: left;
    margin-top: 10px;

    .part-card {
      margin-top: 10px;
      transition: transform 0.2s;
      .part-single-box {
        padding: 10px 10px 10px 10px;
        // border: 1px solid #232324;
        margin: 10px 10px 10px 10px;
        h4 {
        }
        hr {
          //   border-top: 1px dotted #232324 !important;
        }
      }
    }
    .part-card:hover {
      transform: scale(1.1);
    }
  }
  .mandates-main-container {
    margin-top: 20px;
    position: relative;
    display: inline-block;
    .mandates-boxes {
      display: inline-flex;
      .mandates-container {
        position: relative;
        width: 50%;
        margin: 0 10px 0 10px;
        span {
          padding: 25px 15px 15px 15px;
          font-size: 25px;
        }
        img {
          padding: 15px 20px 15px 20px;
        }
        .mandates-content {
          padding: 10px 10px 10px 10px;
        }
        .dark-content {
          text-align: center;
          font-weight: 600;
        }
      }
    }
  }
}
