/* -----------------------------
Prresentation Area 
-------------------------------*/


.presentation-home {
  @media #{$sm-layout} {
    height: auto !important;
    padding: 200px 0;
  }

  .bg-image-1 {
    background-image: url('../../img/slider/presentation/home-presentation-image-01.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .bg-image-2 {
    background-image: url('../../img/slider/presentation/home-presentation-image-02.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;

    &::before {
      position: absolute;
      background: #000;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
    }
  }

  .bg-image-3 {
    background-image: url("../../img/slider/presentation/home-presentation-image-05.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.header-default.light-logo--version .header__wrapper .header-left .logo a img {
  display: block;
}

.header-default.light-logo--version .header__wrapper .header-left .logo a img.black {
  display: none;
}

.header-default.light-logo--version .header__wrapper .header-left .logo a img.light {
  display: block;
}

.header-default.black-logo--version .header__wrapper .header-left .logo a img.light {
  display: none;
}

.header-default.black-logo--version .header__wrapper .header-left .logo a img.black {
  display: block;
}

.header-default.black-logo--version .menu-hamburger.light-version {
  color: #222222
}

.header-default.light-logo--version .menu-hamburger.light-version {
  color: #ffffff
}


.presentation-slider-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  .presentation-inner {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 1;
    height: 100%;
    justify-content: center;
    align-items: center;

    @media #{$sm-layout} {
      display: block;
      height: auto;
    }

    &.contact-address-wrapper {
      .inner {
        text-align: left;
        width: 100%;
        @media #{$laptop-device} {
          padding-left: 81px;
        }
        @media #{$lg-layout} {
          padding-left: 81px;
        }
        @media #{$md-layout} {
          padding-left: 81px;
        }

        @media #{$sm-layout} {
          padding: 0 30px;
        }
      }
    }

    .inner {
      text-align: center;

      &.slider {
        h2 {
          font-size: 110px;

          @media #{$lg-layout} {
            font-size: 70px;
          }

          @media #{$md-layout} {
            font-size: 60px;
          }

          @media #{$sm-layout} {
            font-size: 50px;
          }
        }
      }

      &.about-inner {

        @media #{$laptop-device} {
          padding-left: 81px;
        }

        @media #{$lg-layout} {
          padding-left: 81px;
        }

        @media #{$md-layout} {
          padding-left: 81px;
        }

        @media #{$sm-layout} {
          padding: 0 30px;
        }

        .row {
          background-image: url("../../img/slider/presentation/bgshape.jpg");
          background-repeat: no-repeat;
          background-position: left -22px center;
          background-size: contain;
        }

        .content {
          text-align: left;
          width: 472px;
          max-width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @media #{$sm-layout} {
            width: auto;
          }

          h6 {
            text-transform: uppercase;
          }

          h2 {
            font-family: $heading-font;
            font-size: 48px;
            line-height: 1.11;
            word-wrap: break-word;
            max-width: 100%;

            @media #{$sm-layout} {
              font-size: 32px;
            }

            @media #{$md-layout} {
              font-size: 35px;
            }
          }

          p {
            font-family: $heading-font;
            font-weight: 700;
          }
        }
      }

      &.about-service {
        .row {
          align-items: center;
        }
      }

      &.skill-area {
        text-align: left;
        padding-left: 360px;
        padding-right: 360px;
        min-height: 563px;
        background-image: url(../../img/slider/presentation/home-presentation-image-04.jpg);
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: top right;
        height: 100%;
        display: flex;
        align-items: center;

        @media #{$laptop-device} {
          padding-left: 24px;
          padding-right: 100px;
        }

        @media #{$md-layout} {
          padding-left: 50px;
          padding-right: 50px;
          background-image: none;
        }

        @media #{$sm-layout} {
          padding-left: 30px;
          padding-right: 30px;
          background-image: none;
        }

        .row {

        }
      }


    }
  }
}


#fp-nav ul li button span,
#fp-nav ul li a span {
  display: none;
}

#fp-nav ul li a,
#fp-nav ul li button {
  width: 10px;
  height: 12px;
  background-color: #eee;
  display: inline-block;
  border-radius: 100%;
  line-height: 1;
  border: none;
}

#fp-nav ul li button.active,
#fp-nav ul li a.active {
  background-color: $theme-color;
}

#fp-nav ul {
  position: absolute;
  top: 55%;
  z-index: 3;
  right: 67px;
  padding: 0;
  margin: 0;
  list-style: none;
}


@media #{$sm-layout}{
  #fp-nav ul {
    display: none;
  }
  .fp-tableCell {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner.feature-info {
    padding: 150px 50px 100px;
  }
  .fullpage-btn.mt--130 {
    margin-top: 50px;
  }
}




