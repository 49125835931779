/*==========================
 * Section Title
============================*/

.section-title {
  h3 {
    color: $theme-color;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.43;
    font-family: $bodyFont;
    margin-bottom: 39px;
    @media #{$sm-layout} {
      margin-bottom: 16px;
    }
  }

  h2 {
    font-size: 48px;
    line-height: 1.25;
    margin-bottom: 0;
    word-wrap: break-word;
    max-width: 100%;
    color: #222222;

    @media #{$lg-layout} {
      font-size: 38px;
    }

    @media #{$md-layout} {
      font-size: 38px;
      br {
        display: none;
      }
    }

    @media #{$sm-layout} {
      font-size: 30px;
      br {
        display: none;
      }
    }
  }

  &.white-text {
    h3 {
      color: #ffffff;
    }

    h2 {
      color: #ffffff;
    }
  }

  &--2 {
    h2 {
      font-size: 48px;
      line-height: 1.32;
      font-weight: 700;
      color: #222222;
      font-family: $heading-font;
    }
  }

  &.normal-text {
    & h2 {
      font-weight: 400;
    }
  }
}

.brook-section-title {
  &.white-text {
    .heading {
      color: $white;
    }
  }
}

/* Title Separator */

.title-separator {
  display: block;
  line-height: 0;

  &::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px;
  }

  &.w--80 {
    &::after {
      width: 80px;
    }
  }

  &.w--90 {
    &::after {
      width: 90px;
    }
  }

  &.w--100 {
    &::after {
      width: 100px;
    }
  }

  &.color-red {
    &::after {
      background: $red-color;
    }
  }

  &.color-blue {
    &::after {
      background: $theme-color;
    }
  }
}

/*=====================================
Creative Agency Section Title 
=======================================*/

.theme-creative {
  background-color: #fe378c;
  font-style: italic;
  font-weight: 700;
  background-image: linear-gradient(-133deg, #fe5b34 0, #fe378c 100%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.section-title--between {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .title {
    h3 {
      font-size: 48px;
      line-height: 1.21;
      color: #fff;
      margin-bottom: 0;
      word-wrap: break-word;
      max-width: 100%;
      font-family: "Montserrat", sans-serif;

      span {
        font-family: "Playfair Display", serif;
        font-style: italic;
        font-weight: 700;
      }
    }
  }

  .section-btn {
    a {
      &.button-text {
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        font-family: $bodyFont;
        white-space: nowrap;
        line-height: 1.2;

        i {
          padding-left: 20px;
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          font-size: 12px;
        }

        &:hover {
          i {
            -webkit-transform: translate(5px, -0);
            -ms-transform: translate(5px, -0);
            transform: translate(5px, -0);
          }
        }
      }
    }
  }
}

.title--creative {
  h3 {
    &.heading {
      font-size: 1.4rem;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
      line-height: 1.43;
      word-wrap: break-word;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 34px;
      color: #ffffff;
    }
  }

  h2 {
    font-size: 48px;
    line-height: 1.21;
    color: #fff !important;
    max-width: 470px;
    font-family: "Montserrat", sans-serif;

    span {
      font-family: "Playfair Display", serif;
      font-style: italic;
      font-weight: 700;
    }

    @media #{$sm-layout} {
      br {
        display: none;
      }
    }

    @media #{$md-layout} {
      br {
        display: none;
      }
    }
  }

  p {
    font-size: 18px;
    line-height: 1.67;
    color: #ffffff;
    margin-bottom: 45px;
    word-wrap: break-word;
    max-width: 100%;
    font-family: "Montserrat", sans-serif;
    margin: 0;
    margin-top: 30px;
  }
}
