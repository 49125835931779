/* .sector-slider {
  padding-right: 0;
  ul {
    position: relative;
    list-style: none;
    display: inline-flex;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    .odd {
      position: relative;
      height: 360px !important;
      width: 540px;
      margin: 10px 10px 10px 10px;
    }
    .even {
      position: relative;
      height: 660px;
      width: 400px;
      margin: 10px 10px 10px 10px;
    }
  }
} */

.agri-tech-ul {
  margin-left: 150px;
  list-style: none;
  margin-top: -20px;
}

.agri-tech-li {
  display: flex;
  width: 100%;
  max-width: 600px;
}

.agri-tech-li:before {
  content: "✓";
  display: inline-block;
  width: 25px;
}
